

.swiper-pagination1 {
    position: absolute;
    text-align: center;
    /* transition: 300ms opacity;
    transform: translate3d(0, 0, 0); */
    z-index: 10 !important;
    top: 90% !important;
   
  

    
    
  }

  .swiper-pagination1 .swiper-pagination-bullet {
    width:13px;
    height:13px;
    filter: drop-shadow(0px 8px 24px rgba(18,28,53,0.1));
    
  }
  .swiper-pagination1 .swiper-pagination-bullet-active {
    background: var(--primary,#0DA1DA);
  }
  
  .swiper-paginationauto .swiper-pagination-bullet {
    width:13px;
    height:13px;
    filter: drop-shadow(0px 8px 24px rgba(18,28,53,0.1));
    
  }
  .swiper-paginationauto .swiper-pagination-bullet-active {
    background: var(--primary,#0DA1DA);
  }
  
 .swiper-button-next,
          .swiper-button-prev {
            border:1px solid white !important;
            border-radius: 100%;
            color: white !important;
            width: 3rem !important;
            height: 3rem !important;
          }

.swiper-button-next  {
   
    visibility: visible;
    width: 2rem;
    height: 2rem;
  }
  
  .swiper-button-prev  {
   
    visibility: visible;
    
    
  }