#description h1 {
	font-size: 10rem;
	font-weight: 300;
	margin-bottom: 0.5em;
	margin-top: 0.5em;
	text-align: center;
}

#description h2 {
	font-size: 1.8rem;
	font-weight: 700;
	margin: 1rem 0;
}

#description h3 {
	font-size: 1.2rem;
	font-weight: 500;
	margin: 1rem 0;
}

#description p {
	font-size: 1rem;
	margin: 1rem 0;
}

#description p a {
	color: #ff6d2d;
	text-decoration: underline;
}

#description ol {
	list-style-type: decimal;
}

#description ol li {
	font-size: 1.5rem;
	font-weight: 600;
	margin: 1rem 0;
}

#description ul {
	list-style-type: disc;
}

#description ul li {
	font-size: 1rem;
	margin-left: 3rem;
}

#description img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	border-radius: 1rem;
	margin: 1rem 0;
	box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.dark #description img {
	box-shadow: 0 10px 15px -3px #f0522533, 0 4px 6px -4px #f0522533;
}

@media screen and (max-width: 1024px) {
	#description ol {
		list-style-type: none;
	}
}